import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { hospitality, hospitalityCards } from "../utils/generalInformation";

import SimpleParagraph from "../components/simple-paragraph/simple-paragraph";
import Comparison from "../components/comparison/comparison";


// markup
const Hospitality = () => {


  return (
    <Layout
      SEOTitle={"Hospitality - United Legal Assistance"}
      SEODescription={"Hospitality - United Legal Assistance"}
      footerDisclaimer={""}>

      <SimpleParagraph props={hospitality} />
      <Comparison props={hospitalityCards} />

    </Layout>
  )

}

export default Hospitality